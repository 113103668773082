import log from 'loglevel';
import Vue from 'vue';
import VueRouter, { Location, NavigationGuardNext, Route, RouteConfig } from 'vue-router';

import { serviceWorkerUpdated } from '@/composables/app/ServiceWorkerListener';
import { OngoingMeetingState } from '@/custom_typings/cafexmeetings/meetings-api';
import { AppName } from '@/data/Branding';
import BrowserDetection from '@/data/BrowserDetection';
import Integrations from '@/data/config/Integrations';
import { Track } from '@/data/datatypes/Track';
import { TabInfo } from '@/data/datatypes/UI/TabbedNavigation';
import UserToken from '@/data/datatypes/UserToken';
import Features from '@/data/Features';
import { getFirstDisplayableTab, getRouteForTabInfo } from '@/data/helpers/TrackHelper';
import IosNative from '@/data/IosNative';
import { perfLog, perfMsg } from '@/data/log/PerformanceLog';
import { routerLog } from '@/data/log/RouterLog';
import RouteNames from '@/router/RouteNames';
import pinia from '@/stores';
import { useMeetingStore } from '@/stores/Meeting';
import { useRouteStore } from '@/stores/Route';
import { DEFAULT_COLLABORATION_APP_ID } from '@/stores/Tasks';
import { useTracksStore } from '@/stores/Tracks';
import { useUserStore } from '@/stores/User';
import { GuestTokenRequest, GuestTokenRequestType } from '@/stores/User.types';

Vue.use(VueRouter);

const routeStore = useRouteStore(pinia);
const tracksStore = useTracksStore(pinia);
const meetingStore = useMeetingStore(pinia);
const userStore = useUserStore(pinia);

const enum ExtraAuthState {
  ACCESS_ALLOWED = 'ACCESS_ALLOWED',
  ACCESS_DENIED = 'ACCESS_DENIED',
  REDIRECT = 'REDIRECT',
}

const appName: string = AppName;

const goToLoginPage = (to: Route): void => {
  if (to.query.forceReauth) {
    // We are triggering auth, so remove the forceReauth flag...
    delete to.query.forceReauth;
  }

  let arid = null;
  if (to.query.arid) {
    // A login constraint (arid) has been added to the URL.
    // It will be passed to the login subsystem but here we remove it from the redirect URL
    // (once logged in, it is not needed, as the auth token will embed it).
    arid = to.query.arid;
    delete to.query.arid;
  }

  const adjustedRoute: { route: Route; href: string; } = router.resolve({
    path: to.path,
    hash: to.hash,
    query: to.query,
    params: to.params,
    append: false,
    replace: true
  });

  const redirectEncoded: string = encodeURIComponent(adjustedRoute.href);
  let loginUrl: string = `${Integrations.BE}/login?redirect=${redirectEncoded}`;
  if (arid) {
    loginUrl += `&arid=${arid}`;
  }
  window.location.replace(loginUrl);

  if (BrowserDetection.isIosApp()) {
    IosNative.invokeNative('startNativeLogin', { debugInfo: 'index.ts::goToLoginPage' });
  }
};

const base64urlDecode = (encoded: string) => {
  const padding = '='.repeat((4 - encoded.length % 4) % 4);
  const base64 = (encoded + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  return window.atob(base64);
};

const getNotificationTargetUrl = (to: Route) => {
  const trackId = to.params?.trackId;
  const param1 = to.params?.param1;
  const trackType = to.params?.trackType;
  const target = to.params?.target;
  // IMPORTANT: This method needs to be kept consistent with what the NotificationService.java class does.

  let url = '/'; // By default send to the home page

  if (trackType === 'PRIVATE_CHAT') {
    url = `/c/${trackId}`;
  } else if (target === 'entry') {
    url = `/t/${trackId}/e/${param1}`;
  } else if (target === 'chat') {
    if (param1 !== 'null') {
      url = `/t/${trackId}/e/${param1}`;
    } else {
      url = `/t/${trackId}/c`;
    }
  } else if (target === 'task') {
    url = `/t/${trackId}/r/${param1}`;
  } else if (target === 'kb') {
    url = base64urlDecode(param1);
  } else if (trackId !== 'null') {
    url = `/t/${trackId}`;
  }

  // If there is an asterisk in the resulting URL/path, the router
  // will drop back to the default route with a warning in the console.
  // Escaping the asterisk fixes the issue and the escaping '\\' is removed
  // when it's actually used as a URL.
  url = url.replaceAll('*', '\\*');

  return url;
};

const requestMeetingGuestToken = async (trackId: string, userAuthenticated: boolean): Promise<UserToken> => {
  const tokenRequest: GuestTokenRequest = {
    type: GuestTokenRequestType.DEFAULT,
    entryId: 'meeting',
    trackId: trackId,
    ignoreCachedToken: true, // make sure we get a fresh token when we first join a meeting
    userAuthenticated
  };
  return await userStore.getGuestToken(tokenRequest);
};

const requestAppGuestToken = async (trackId: string, userAuthenticated: boolean): Promise<UserToken> => {
  const tokenRequest: GuestTokenRequest = {
    type: GuestTokenRequestType.APP,
    trackId: trackId,
    ignoreCachedToken: false,
    userAuthenticated
  };
  return await userStore.getGuestToken(tokenRequest);
};

/**
 * @param route The route to check.
 * @returns Returns true if given route is safe to be reloaded. Some routes should not be reloaded
 * even is the user is IDLE. This is typically because that would cause data loss or disturb the UI.
 * Examples are the tenant settings page or being in a Meeting...
 */
export const safeToReload = (route: Route): boolean => {
  const navigationFromAdminPage = route && (route.name === RouteNames.TENANT_SETTINGS_ROUTE_NAME ||
    route.name === RouteNames.SYSTEM_SETTINGS_ROUTE_NAME);
  if (navigationFromAdminPage) {
    // The admin pages have navigation guards that get messy if we try and reload the page, so don't try and reload
    // when navigating away from them.
    routerLog.debug('Cancelled page reload (software update) as user is in admin panel');
    return false;
  }

  // TODO: We need to detect in-app meetings too
  const inMeeting: OngoingMeetingState = meetingStore.inMeeting;
  if (inMeeting === OngoingMeetingState.IN_PRE_MEET || inMeeting === OngoingMeetingState.IN_MEETING) {
    // If we reload the page while in a meeting then we'll kick the user out of the meeting, so wait until it's over
    routerLog.debug('Cancelled page reload (software update) as user is in a call');
    return false;
  }
  return true;
};

/**
 * Forces a reload (by changing the location.href) if there is a pending update we need to apply.
 *
 * This function returns true if the reload is in progress and false otherwise (this will be used to know
 * if the router should apply its own route change or just if it should just "hang" and wait for the
 * reload to occur).
 */
const reloadIfSwUpdated = (from: Route, to: Route): boolean => {
  if (!serviceWorkerUpdated) {
    // If the service worker hasn't flagged that there's an update, then there's nothing to do
    return false;
  }

  if (!safeToReload(from)) {
    return false;
  }

  routerLog.debug('Need to apply software update => Route transition done by forced reload');

  // If the service worker has updated then take the navigation as an opportunity to refresh the page and get the
  // updated code
  // Resolve a known route so that we know the full URL that the server requires, including the path prefix
  const homeRoute: { route: Route; href: string; } = router.resolve(RouteNames.HOME_ROUTE_NAME);
  const homeHref: string = homeRoute.href;
  // The prefix is whatever is left if you remove the origin and the full path
  const prefix: string = homeHref.replace(window.location.origin, '').replace(homeRoute.route.fullPath, '');

  let newHref = `${window.location.origin}${prefix}${to.fullPath}`;
  if (new URLSearchParams(location.search).get('wcs') === 'true' && !newHref.includes('wcs=true')) {
    if (newHref.includes('?')) {
      newHref += '&wcs=true';
    } else {
      newHref += '?wcs=true';
    }
  }
  if (newHref === window.location.href) {
    // The user probably clicked an <a> or something, which changed the window location before the router was invoked.
    window.location.reload();
  } else {
    window.location.href = newHref;
  }

  return true;
};

// In some circumstances (e.g. guest entry auth handling), we need to make sure that an up to date track list is being
// used. If the track store has _never_ done an update, this function can be used to wait until an initial track load
// has completed.
export const waitForTrackListUpdate = async (timeoutMillis: number = 30_000): Promise<void> => {
  const lastTrackUpdate = tracksStore.lastTrackDataUpdate;
  if (!lastTrackUpdate) {
    // Dispatch the track refresh but do _not_ await it
    tracksStore.refreshTracks();
  }
  return new Promise<void>((resolve, reject) => {
    const startTime = Date.now();
    const checkForTrackList = () => {
      if (tracksStore.lastTrackDataUpdate) {
        resolve();
      } else if (Date.now() - startTime > timeoutMillis) {
        reject(new Error('Timed out waiting for tracks list to update'));
      } else {
        setTimeout(checkForTrackList, 500);
      }
    };
    checkForTrackList();
  }).catch(err => {
    throw (err);
  });
};

const isMemberOfTrack = async (trackId: string): Promise<boolean> => {
  try {
    const trackListStart = performance.now();
    perfLog.debug(perfMsg('Waiting for track list update'));
    // If the track data hasn't been updated at all, refresh the track list
    await waitForTrackListUpdate();
    perfLog.debug(perfMsg('Finished waiting for track list update', false, trackListStart));

    // If it's a track we're a member of, we can join the meeting / view the app
    const allTracks: Track[] = tracksStore.allTracks;
    const isOneOfMyTracks: boolean = !!allTracks?.find(track => track.id === trackId);
    return isOneOfMyTracks;
  } catch (err) {
    log.warn('Could not retrieve tracks; we can still attempt guest access');
  }
  return false;
};

// If we're joining a meeting for a track we're a member of, we don't need a guest token. If we aren't logged in, or
// we're joining a track we are _not_ a member of, we do need one.
const meetingGuestHandler = async (to: Route, isAlreadyAuthed: boolean, next: NavigationGuardNext)
  : Promise<ExtraAuthState> => {
  const trackId = to.params.trackId;
  const entryId = to.params.entryId;

  if (isAlreadyAuthed) {
    const isMember: boolean = await isMemberOfTrack(trackId);
    if (isMember) {
      return ExtraAuthState.ACCESS_ALLOWED;
    }
  }

  let guestToken = null;
  try {
    guestToken = await requestMeetingGuestToken(trackId, isAlreadyAuthed);
    if (!entryId) {
      return guestToken ? ExtraAuthState.ACCESS_ALLOWED : ExtraAuthState.ACCESS_DENIED;
    } else {
      if (entryId && guestToken.meid === entryId) {
        next({ name: RouteNames.TRACK_MEETING_ROUTE_NAME, params: { trackId: trackId }, replace: true });
        // return REDIRECT so we know to avoid doing the next() as it's already happened here
        return ExtraAuthState.REDIRECT;
      } else if (isAlreadyAuthed) {
        // return ACCESS_ALLOWED so we don't attempt to re-auth and get stuck in a redirect loop -
        // the appropriate not found page should be displayed if the user doesn't have access to supplied track
        return ExtraAuthState.ACCESS_ALLOWED;
      }
      // auth required
      return ExtraAuthState.ACCESS_DENIED;
    }
  } catch (err) {
    log.error('Failed to get a guest token for this meeting');
    return ExtraAuthState.ACCESS_DENIED;
  }
};

const guestAppViewAuthHandler = async (to: Route, isAlreadyAuthed: boolean, next: NavigationGuardNext):
  Promise<ExtraAuthState> => {
  const trackId = to.params.trackId;
  if (isAlreadyAuthed) {
    const isMember: boolean = await isMemberOfTrack(trackId);
    if (isMember) {
      return ExtraAuthState.ACCESS_ALLOWED;
    }
  }
  let guestAccessAllowed: boolean = false;
  try {
    const guestToken = await requestAppGuestToken(trackId, isAlreadyAuthed);
    if (guestToken) {
      guestAccessAllowed = true;
    }
  } catch (err) {
    log.error('Failed to get a guest token for this app');
  }
  if (guestAccessAllowed) {
    return ExtraAuthState.ACCESS_ALLOWED;
  } else {
    if (isAlreadyAuthed) {
      // Just take them back to the homepage.
      next({ name: RouteNames.HOME_ROUTE_NAME });
      return ExtraAuthState.REDIRECT;
    } else {
      return ExtraAuthState.ACCESS_DENIED;
    }
  }
};

const translateNonAppRoute = async (path: string): Promise<Location | undefined> => {
  let tenantAppId: string = DEFAULT_COLLABORATION_APP_ID;
  // Turn the /t/trackId/... route into /a/tenantAppId/t/trackId/...
  const query: URLSearchParams = new URLSearchParams(location.search);
  // Check if this is flagged as coming from the workspace creation service.
  const isWcsRequest = query.get('wcs') === 'true';
  if (isWcsRequest) {
    // If there was no service worker when the request hit the server then we'll have a v1 address, but it will have
    // given us the app ID in a query parameter to avoid us having to work it out here.
    const appIdFromQueryParam = query.get('wcsAppId');
    if (appIdFromQueryParam) {
      tenantAppId = appIdFromQueryParam;
    }
  }
  if (path.length > 38) {
    const trackId: string = path.substring(3, 39);
    try {
      await waitForTrackListUpdate(5_000);
    } catch (err) {
      log.debug('Timed out waiting for track list', err);
    }
    if (tenantAppId === DEFAULT_COLLABORATION_APP_ID) {
      const track: Track | undefined = tracksStore.tracks?.[trackId];
      if (track) {
        if (track.owningMiniAppId) {
          tenantAppId = track.owningMiniAppId;
        }
      }
    }
  }
  let wcsAppend = '';
  if (isWcsRequest) {
    // Maintain the workspace creation service flag
    wcsAppend = '?wcs=true';
  }
  const newRoute: string = `/a/${tenantAppId}${path}${wcsAppend}`;
  return router.resolve(newRoute).location;
};

const routes: Array<RouteConfig> = [
  {
    path: '/reconnect',
    name: RouteNames.RECONNECT_ROUTE_NAME,
    component: () => import(/* webpackChunkName: "home" */ '@/views/ReconnectTab.vue'),
    meta: {
      title: `${appName} - Reconnecting`,
      requiresAuth: true,
    },
  },
  {
    path: '/message',
    name: RouteNames.SERVER_MESSAGE_ROUTE_NAME,
    component: () => import(/* webpackChunkName: "servermessage" */ '@/views/ServerMessage.vue'),
    meta: {
      title: `${appName}`,
      requiresAuth: true,
    },
  },
  {
    path: '/thankyou',
    name: RouteNames.THANKYOU_ROUTE_NAME,
    component: () => import(/* webpackChunkName: "thankyou" */ '@/views/ThankYou.vue'),
    meta: {
      title: `${appName} - Thank you`,
      requiresAuth: false,
      guestOnly: true,
    }
  },
  {
    path: '/',
    name: RouteNames.HOME_ROUTE_NAME,
    component: () => import(/* webpackChunkName: "home" */ '@/views/HomeDashboard.vue'),
    meta: {
      title: `${appName} - Home`,
      requiresAuth: true,
    },
  },
  {
    /**
     * Special route used by the Safari Desktop notifications. They can only fixed parameterized URLs.
     * This special entry point is responsible for redirecting to the proper, real, URL.
     * - param1 will typically be the entryId but its value depends of trackType and target.
     * - trackType is the track type.
     * - target is the target of the notification. Often used as a differentiator
     *   when the track type is not enough to tell and more context is needed.
     *
     * Changes here will have to be reflected in the WebPushSender.buildApnsMessageUrlArgs() method.
     *
     * See: https://gitlab.com/cafex-communications/fender/fender/-/blob/master/apns/README.md?ref_type=heads#application-code-changes-to-notification-url-arguments
     *
     */
    path: '/notify/:trackId/:param1/:trackType/:target',
    name: 'notify',
    redirect: getNotificationTargetUrl,
  },
  {
    path: '/apps',
    name: RouteNames.APPS_ROUTE_NAME,
    component: () => import(/* webpackChunkName: "miniapp-admin" */ '@/views/MainTracks.vue'),
    meta: {
      title: `${appName} - Apps`,
      requiresAuth: true,
    },
    props: true,
    children: [
      {
        path: ':tenantAppId',
        name: RouteNames.EDIT_APP_ROOT_ROUTE_NAME,
        component: () => import(/* webpackChunkName: "miniapp-admin" */ '@/views/apps/MiniAppView.vue'),
        children: [
          {
            path: '',
            name: RouteNames.EDIT_APP_ROUTE_NAME,
            component: () => import(/* webpackChunkName: "miniapp-admin" */ '@/views/apps/EditApp.vue'),
            meta: {
              requiresAuth: true,
            },
            props: true,
          },
          {
            path: 'd',
            name: RouteNames.EDIT_APP_DETAILS_ROUTE_NAME,
            component: () => import(/* webpackChunkName: "miniapp-admin" */ '@/views/apps/EditApp.vue'),
            meta: {
              requiresAuth: true,
            },
            props: true,
          },
          {
            path: 'p',
            name: RouteNames.EDIT_APP_PERMISSIONS_ROUTE_NAME,
            component: () => import(/* webpackChunkName: "miniapp-admin" */ '@/views/apps/EditApp.vue'),
            meta: {
              requiresAuth: true,
            },
            props: true,
          },
          {
            path: 'wf',
            name: RouteNames.EDIT_APP_WORKSPACE_LABELS_ROUTE_NAME,
            component: () => import(/* webpackChunkName: "miniapp-admin" */ '@/views/apps/EditApp.vue'),
            meta: {
              requiresAuth: true,
            },
            props: true,
          },
          {
            path: 't',
            name: RouteNames.EDIT_APP_TABLES_ROUTE_NAME,
            component: () => import(/* webpackChunkName: "miniapp-admin" */ '@/views/apps/EditApp.vue'),
            meta: {
              requiresAuth: true,
            },
            props: true,
          },
          {
            path: 'v',
            name: RouteNames.EDIT_APP_VIEWS_ROUTE_NAME,
            component: () => import(/* webpackChunkName: "miniapp-admin" */ '@/views/apps/EditApp.vue'),
            meta: {
              requiresAuth: true,
            },
            props: true,
          },
          {
            path: 'uf',
            name: RouteNames.EDIT_APP_UI_FLOWS_ROUTE_NAME,
            component: () => import(/* webpackChunkName: "miniapp-admin" */ '@/views/apps/EditApp.vue'),
            meta: {
              requiresAuth: true,
            },
            props: true,
            children: [
              {
                path: 'new',
                name: RouteNames.CREATE_UI_FLOW_ROUTE_NAME,
                component: () => {
                  return import(/* webpackChunkName: "miniapp-admin-uiflow" */
                    '@/views/apps/uiFlow/ConfigureUiFlow.vue');
                },
                meta: {
                  requiresAuth: true,
                },
                props: true,
              },
              {
                path: 'edit/:uiFlowId',
                name: RouteNames.EDIT_UI_FLOW_ROUTE_NAME,
                component: () => {
                  return import(/* webpackChunkName: "miniapp-admin-uiflow" */
                    '@/views/apps/uiFlow/ConfigureUiFlow.vue');
                },
                meta: {
                  requiresAuth: true,
                },
                props: true,
              },
            ],
          },
          {
            path: 'ds',
            name: RouteNames.EDIT_APP_DATA_SOURCES_ROUTE_NAME,
            component: () => import(/* webpackChunkName: "miniapp-admin" */ '@/views/apps/EditApp.vue'),
            meta: {
              requiresAuth: true,
            },
            props: true,
          },
          {
            path: 'ds',
            name: RouteNames.EDIT_APP_DATA_SOURCES_ROUTE_NAME,
            component: () => import(/* webpackChunkName: "miniapp-admin" */ '@/views/apps/EditApp.vue'),
            meta: {
              requiresAuth: true,
            },
            props: true,
          },
          {
            path: 'w',
            name: RouteNames.EDIT_APP_WORKFLOWS_ROUTE_NAME,
            component: () => import(/* webpackChunkName: "miniapp-admin" */ '@/views/apps/EditApp.vue'),
            meta: {
              requiresAuth: true,
            },
            props: true,
            children: [
              {
                path: 'flows/:recipeId',
                name: RouteNames.EDIT_WORKFLOW_ROUTE_NAME,
                component: () => {
                  if (userStore.isUserFeatureEnabled(Features.ACTIVEPIECES)) {
                    return import(
                      '@/components/tasks/ActivePiecesFlowView.vue');
                  }
                },
                meta: {
                  requiresAuth: true,
                },
                props: true,
              },
              {
                path: 'runs',
                name: RouteNames.FLOW_RUNS_ROUTE_NAME,
                component: () => import(/* webpackChunkName: "miniapp-admin" */
                  '@/components/tasks/ActivePiecesRunsView.vue'),
                meta: {
                  requiresAuth: true,
                },
                props: true,
              },
              {
                path: 'connections',
                name: RouteNames.FLOW_CONNECTIONS_ROUTE_NAME,
                component: () => import(/* webpackChunkName: "miniapp-admin" */
                  '@/components/tasks/ActivePiecesConnectionsView.vue'),
                meta: {
                  requiresAuth: true,
                },
                props: true,
              },
            ],
          },
          {
            path: 'rs',
            name: RouteNames.APP_RULESETS_LIST_ROUTE_NAME,
            component: () => import(/* webpackChunkName: "miniapp-admin" */ '@/views/apps/EditApp.vue'),
            meta: {
              requiresAuth: true,
            },
            props: true,
            children: [
              {
                path: 'new',
                name: RouteNames.CREATE_APP_RULESETS_ROUTE_NAME,
                component: () => {
                  return import(/* webpackChunkName: "miniapp-admin-ruleset" */
                    '@/views/rulesengine/RulesetView.vue');
                },
                meta: {
                  requiresAuth: true,
                },
              },
              {
                path: 'edit/:rulesetId',
                name: RouteNames.EDIT_APP_RULESETS_ROUTE_NAME,
                component: () => {
                  return import(/* webpackChunkName: "miniapp-admin-ruleset" */
                    '@/views/rulesengine/RulesetView.vue');
                },
                meta: {
                  requiresAuth: true,
                },
              },
              {
                path: 'test/:rulesetId',
                name: RouteNames.TEST_APP_RULESETS_ROUTE_NAME,
                component: () => {
                  return import(/* webpackChunkName: "miniapp-admin-ruleset" */
                    '@/views/rulesengine/DebugRulesetView.vue');
                },
              },
            ],
          },
          {
            path: 'tt',
            name: RouteNames.EDIT_APP_TRACK_TEMPLATES_ROUTE_NAME,
            component: () => import(/* webpackChunkName: "miniapp-admin" */ '@/views/apps/EditApp.vue'),
            meta: {
              requiresAuth: true,
            },
            props: true,
          },
          {
            path: 'cc',
            name: RouteNames.APP_CONTEXT_CARDS_LIST_ROUTE,
            component: () => import(/* webpackChunkName: "miniapp-admin" */ '@/views/apps/EditApp.vue'),
            meta: {
              requiresAuth: true,
            },
            props: true,
            children: [
              {
                path: 'new',
                name: RouteNames.CREATE_APP_CONTEXT_CARD_ROUTE_NAME,
                component: () => import(/* webpackChunkName: "contextcards" */ '@/views/ContextCardEditor.vue'),
                meta: {
                  requiresAuth: true,
                },
                props: true,
              },
              {
                path: 'edit/:contextCardId',
                name: RouteNames.EDIT_APP_CONTEXT_CARD_ROUTE_NAME,
                component: () => import(/* webpackChunkName: "contextcards" */ '@/views/ContextCardEditor.vue'),
                meta: {
                  requiresAuth: true,
                },
                props: true,
              }
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/apps/:tenantAppId/v/cv/:customViewId',
    name: RouteNames.EDIT_APP_CUSTOM_VIEW_ROUTE_NAME,
    component: () =>
      import(/* webpackChunkName: "miniapp-admin-customview" */ '@/views/uidesigner/EditCustomView.vue'),
    meta: {
      title: `${appName} - Edit custom view`,
      requiresAuth: true,
    },
  },
  {
    path: '/search',
    name: RouteNames.SEARCH_ROUTE_NAME,
    component: () => import(/* webpackChunkName: "search" */ '@/views/SearchView.vue'),
    meta: {
      title: `${appName} - Search`,
      requiresAuth: true,
    },
    props: route => ({
      searchTerm: route.query.search,
      searchLocation: route.query.location,
      searchDate: route.query.dateQuery,
      searchTimestamp: route.query.ts,
      trackId: route.query.t,
      tenantId: route.query.te,
      ownerId: route.query.owner,
      customDateOne: route.query.d1,
      customDateTwo: route.query.d2,
    }),
  },
  {
    path: '/t/',
    name: 'tmpTracks',
    component: () => import(/* webpackChunkName: "tracks" */ '@/views/MainTracks.vue'),
    meta: {
      title: `${appName} - Workspaces`,
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'tmp' + 'MostRecentTrack',
        component: () => import(/* webpackChunkName: "track" */ '@/views/track/MostRecentTrack.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':trackId',
        name: 'tmp' + RouteNames.TRACK_MAIN_BASE_ROUTE_NAME,
        meta: {
          requiresAuth: true,
          // extraAuth: entryGuestHandler,
        },
        components: {
          default: () => import(/* webpackChunkName: "track" */ '@/views/track/TrackMainBase.vue'),
          // secondary: () => import(/* webpackChunkName: "track" */ '@/components/meetings/MeetingSmallWindow'),
          tertiary: () => import(/* webpackChunkName: "track" */ '@/views/track/TrackSideBar.vue'),
        },
        children: [
          {
            path: 'meet',
            name: 'tmp' + RouteNames.TRACK_MEETING_ROUTE_NAME,
            meta: {
              // requiresAuth: false,
              requiresAuth: true,
              extraAuth: meetingGuestHandler,
            },
            components: {
              trackView: () => import(/* webpackChunkName: "track" */ '@/components/meetings/TrackMeeting.vue'),
            },
          },
          {
            path: '*',
            name: 'tmpTrackCatchAll',
            meta: {
              requiresAuth: true,
            },
            components: {
              trackView: () => import(/* webpackChunkName: "track" */ '@/views/track/TrackDashboardView.vue')
            }
          },
        ],
      },
    ],
  },
  {
    path: '/a/:tenantAppId/t/',
    name: 'Tracks',
    component: () => import(/* webpackChunkName: "tracks" */ '@/views/MainTracks.vue'),
    meta: {
      title: `${appName} - Workspaces`,
      requiresAuth: true,
    },
    children: [
      {
        path: 'new',
        name: 'NewTrack',
        component: () => import(/* webpackChunkName: "track" */ '@/components/track/AddTrack.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '',
        name: RouteNames.MOST_RECENT_TRACK_ROUTE_NAME,
        component: () => import(/* webpackChunkName: "track" */ '@/views/track/MostRecentTrack.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':trackId',
        name: RouteNames.TRACK_MAIN_BASE_ROUTE_NAME,
        meta: {
          requiresAuth: true,
          // extraAuth: entryGuestHandler,
        },
        components: {
          default: () => import(/* webpackChunkName: "track" */ '@/views/track/TrackMainBase.vue'),
          // secondary: () => import(/* webpackChunkName: "track" */ '@/components/meetings/MeetingSmallWindow'),
          tertiary: () => import(/* webpackChunkName: "track" */ '@/views/track/TrackSideBar.vue'),
        },
        children: [
          {
            path: 'e',
            name: RouteNames.TRACK_ENTRIES_ROUTE_NAME,
            components: {
              trackView: () => import(/* webpackChunkName: "track" */ '@/views/track/TrackEntriesView.vue'),
            },
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'e/:entryId',
            components: {
              // As we need to handle entry type links, may change later when actual entry is implemented
              trackView: () => import(/* webpackChunkName: "track" */ '@/components/track/TrackFileEntry.vue'),
            },
            props: {
              trackView: true,
            },
            children: [
              {
                path: '',
                name: RouteNames.TRACK_ENTRY_ROUTE_NAME,
                components: {
                  // secondary:
                  //   () => import(/* webpackChunkName: "track" */ '@/components/meetings/MeetingSmallWindow'),
                },
                meta: {
                  requiresAuth: true,
                  extraAuth: meetingGuestHandler,
                },
                props: {
                  default: true,
                },
              },
            ],
          },
          {
            path: 'f/:entryId',
            name: RouteNames.TRACK_ENTRIES_VIEW_ROUTE_NAME,
            meta: {
              requiresAuth: true,
            },
            components: {
              trackView: () => import(/* webpackChunkName: "track" */ '@/views/track/TrackEntriesView.vue')
            }
          },
          {
            path: '',
            name: RouteNames.TRACK_DEFAULT_ROUTE_NAME,
            meta: {
              requiresAuth: true,
            },
            components: {
              trackView: () => import(/* webpackChunkName: "track" */ '@/views/track/TrackDashboardView.vue')
            }
          },
          {
            path: 'd',
            name: RouteNames.TRACK_DASHBOARD_ROUTE_NAME,
            meta: {
              requiresAuth: true,
            },
            components: {
              trackView: () => import(/* webpackChunkName: "track" */ '@/views/track/TrackDashboardView.vue')
            }
          },
          {
            path: 'history',
            name: RouteNames.TRACK_HISTORY_ROUTE_NAME,
            meta: {
              requiresAuth: true,
            },
            components: {
              trackView: () => import(/* webpackChunkName: "track" */ '@/views/track/TrackHistory.vue')
            }
          },
          {
            path: 'o',
            name: RouteNames.TRACK_INFO_ROUTE_NAME,
            meta: {
              requiresAuth: true,
            },
            components: {
              trackView: () => import(/* webpackChunkName: "track" */ '@/views/track/TrackInfo.vue')
            }
          },
          {
            path: 'ct',
            name: RouteNames.ALL_CHAT_THREADS_ROUTE_NAME,
            meta: {
              requiresAuth: true,
            },
            components: {
              trackView: () => import(/* webpackChunkName: "chat" */ '@/views/chat/AllChatThreadsView.vue'),
            },
            props: {
              trackView: true,
            }
          },
          {
            path: 'ct/:parentChatId',
            name: RouteNames.SINGLE_CHAT_THREAD_ROUTE_NAME,
            meta: {
              requiresAuth: true,
            },
            components: {
              trackView: () => import(/* webpackChunkName: "track" */ '@/views/chat/SingleChatThreadView.vue'),
            },
            props: {
              trackView: true,
            }
          },
          {
            path: 'c',
            name: RouteNames.TRACK_CHAT_ROUTE_NAME,
            meta: {
              requiresAuth: true,
            },
            components: {
              trackView: () => import(/* webpackChunkName: "track" */ '@/views/track/TrackChatChat.vue'),
            },
          },
          {
            path: 'meetings',
            name: RouteNames.TRACK_MEETINGS_ROUTE_NAME,
            meta: {
              requiresAuth: true,
            },
            components: {
              trackView: () => import(/* webpackChunkName: "track" */ '@/components/meetings/TrackMeetings.vue'),
            },
          },
          {
            path: 'meet',
            name: RouteNames.TRACK_MEETING_ROUTE_NAME,
            meta: {
              // requiresAuth: false,
              requiresAuth: true,
              extraAuth: meetingGuestHandler,
            },
            components: {
              trackView: () => import(/* webpackChunkName: "track" */ '@/components/meetings/TrackMeeting.vue'),
            },
          },
          {
            path: 'r/:taskId',
            name: RouteNames.SINGLE_TRACK_TASK_ROUTE_NAME,
            meta: {
              requiresAuth: true,
            },
            components: {
              trackView: () => import(/* webpackChunkName: "track" */ '@/components/tasks/SingleTrackTaskView.vue')
            }
          },
          {
            path: 'a',
            name: RouteNames.TRACK_TASKS_ROUTE_NAME,
            meta: {
              requiresAuth: true,
            },
            components: {
              trackView: () => import(/* webpackChunkName: "track" */ '@/components/tasks/TaskCoreWrapper.vue'),
            },
            children: [
              {
                path: ':appId',
                name: RouteNames.TRACK_APP_ROUTE_NAME,
                components: {
                  trackView: () => import(/* webpackChunkName: "track" */ '@/components/tasks/TaskCoreWrapper.vue'),
                },
                meta: {
                  requiresAuth: true,
                },
                children: [
                  {
                    path: 'v/:viewId',
                    name: RouteNames.TRACK_APP_VIEW_ROUTE_NAME,
                    components: {
                      trackView: () => import(/* webpackChunkName: "track" */ '@/components/tasks/TaskCoreWrapper.vue'),
                    },
                    meta: {
                      requiresAuth: true,
                    },
                  },
                  {
                    path: 'edit',
                    name: RouteNames.TRACK_EDIT_APP_ROUTE_NAME,
                    components: {
                      trackView: () => import(/* webpackChunkName: "miniapp-admin" */ '@/views/apps/EditTrackApp.vue'),
                    },
                    meta: {
                      requiresAuth: true,
                    },
                    props: {
                      trackView: true,
                    },
                    children: [
                      {
                        path: 'd',
                        name: RouteNames.TRACK_EDIT_APP_DETAILS_ROUTE_NAME,
                        meta: {
                          requiresAuth: true,
                        },
                      },
                      {
                        path: 't',
                        name: RouteNames.TRACK_EDIT_APP_TABLES_ROUTE_NAME,
                        meta: {
                          requiresAuth: true,
                        },
                      },
                      {
                        path: 'v',
                        name: RouteNames.TRACK_EDIT_APP_VIEWS_ROUTE_NAME,
                        meta: {
                          requiresAuth: true,
                        },
                      },
                      {
                        path: 'w',
                        name: RouteNames.TRACK_EDIT_APP_WORKFLOWS_ROUTE_NAME,
                        meta: {
                          requiresAuth: true,
                        },
                      },
                      {
                        path: 'ds',
                        name: RouteNames.TRACK_EDIT_APP_DATA_SOURCES_ROUTE_NAME,
                        meta: {
                          requiresAuth: true,
                        },
                      },
                    ],
                  },
                ],
              },
            ]
          },
          {
            path: 'a/:appId/uf',
            name: RouteNames.WORKSPACE_CREATE_UI_FLOW_ROUTE_NAME,
            components: {
              trackView: () =>
                import(/* webpackChunkName: "miniapp-admin-uiflow" */ '@/views/apps/uiFlow/ConfigureUiFlow.vue'),
            },
            meta: {
              requiresAuth: true,
            },
            props: {
              trackView: true,
            },
          },
          {
            path: 'a/:appId/uf/:uiFlowId',
            name: RouteNames.WORKSPACE_EDIT_UI_FLOW_ROUTE_NAME,
            components: {
              trackView: () =>
                import(/* webpackChunkName: "miniapp-admin-uiflow" */ '@/views/apps/uiFlow/ConfigureUiFlow.vue'),
            },
            meta: {
              requiresAuth: true,
            },
            props: {
              trackView: true,
            },
          },
          {
            path: 'uf/:uiFlowId',
            name: RouteNames.TRACK_UI_FLOW_ROUTE_NAME,
            meta: {
              requiresAuth: true,
            },
            components: {
              trackView: () => import(/* webpackChunkName: "uiflow" */ '@/views/track/UIFlowView.vue'),
            },
            props: {
              trackView: true,
            },
          },
          {
            path: 'v',
            name: RouteNames.TRACK_APP_GUEST_VIEW_ROUTE_NAME,
            meta: {
              requiresAuth: true,
              extraAuth: guestAppViewAuthHandler,
            },
            components: {
              trackView: () => import(/* webpackChunkName: "guest" */ '@/views/apps/AppGuestView.vue'),
            },
          },
        ]
      },
    ],
  },
  {
    path: '/m',
    name: RouteNames.MEETINGS_ROUTE_NAME,
    component: () => import(/* webpackChunkName: "meetings" */ '@/views/CalendarMeetings.vue'),
    meta: {
      title: `${appName} - Calendar`,
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'MeetingList',
        meta: {
          requiresAuth: true,
        },
        components: {
          default: () => import(/* webpackChunkName: "meetings" */ '@/components/meetings/MeetingList.vue'),
        }
      },
      {
        path: ':filter',
        name: RouteNames.CALENDAR_ROUTE_NAME,
        meta: {
          requiresAuth: true,
        },
        components: {
          default: () => import(/* webpackChunkName: "meetings" */ '@/views/CalendarMeetings.vue')
        },
        children: [
          {
            path: ':year/:month/:date',
            name: RouteNames.CALENDAR_FILTER_ROUTE_NAME,
            meta: {
              requiresAuth: true,
            },
            components: {
              default: () => import(/* webpackChunkName: "meetings" */ '@/views/CalendarMeetings.vue')
            }
          },
        ]
      },
      {
        path: 't',
        name: RouteNames.MOST_RECENT_CALENDAR_TRACK_ROUTE_NAME,
        meta: {
          requiresAuth: true,
        },
        components: {
          default: () => import(/* webpackChunkName: "meetings" */ '@/views/CalendarMeetings.vue'),
          secondary: () => import(/* webpackChunkName: "meetings" */ '@/views/track/MostRecentTrack.vue'),
        },
        children: [
          {
            path: ':trackId',
            name: RouteNames.TRACK_CALENDAR_ROUTE_NAME,
            meta: {
              requiresAuth: true,
            },
            components: {
              default: () => import(/* webpackChunkName: "meetings" */ '@/views/CalendarMeetings.vue'),
            },
            children: [
              {
                path: ':filter',
                name: RouteNames.TRACK_CALENDAR_ROUTE_LEVEL_NAME,
                meta: {
                  requiresAuth: true,
                },
                components: {
                  default: () => import(/* webpackChunkName: "meetings" */ '@/views/CalendarMeetings.vue'),
                },
                children: [
                  {
                    path: ':year/:month/:date',
                    name: RouteNames.TRACK_CALENDAR_FILTER_ROUTE_NAME,
                    meta: {
                      requiresAuth: true,
                    },
                    components: {
                      default: () => import(/* webpackChunkName: "meetings" */ '@/views/CalendarMeetings.vue'),
                    }
                  },
                ]
              },
            ]
          },
        ]
      },
      {
        path: 'schedule',
        name: 'ScheduleMeeting',
        meta: {
          requiresAuth: true,
        },
        components: {
          default: () => import(/* webpackChunkName: "meetings" */ '@/views/ScheduleMeeting.vue'),
        }
      },
    ]
  },
  // This does NOT work. '/m/new' matches the preview route '/m/:filter', and sets 'filter' to the invalid value of
  // 'new'
  {
    path: '/m/new',
    name: 'NewMeeting',
    component: () => import(/* webpackChunkName: "meetings" */ '@/components/meetings/NewMeeting.vue'),
    meta: {
      title: `${appName} - Meetings`,
      requiresAuth: true,
    },
  },
  {
    path: '/p',
    name: 'People',
    component: () => import(/* webpackChunkName: "people" */ '@/views/PeopleView.vue'),
    meta: {
      title: `${appName} - People`,
      requiresAuth: true,
    },

    children: [
      {
        path: '',
        name: RouteNames.CONTACT_LIST_ROUTE_NAME,
        meta: {
          title: `${appName} - Contacts`,
          requiresAuth: true,
        },
        components: {
          default: () => import(/* webpackChunkName: "people" */ '@/views/people/ContactList.vue'),
        },
        children: [
          {
            path: '',
            name: RouteNames.CONTACT_LIST_ROUTE_NAME,
            meta: {
              title: `${appName} - Contacts`,
              requiresAuth: true,
            },
          },
          {
            path: 'personal',
            name: RouteNames.PERSONAL_CONTACT_LIST_ROUTE_NAME,
            meta: {
              title: `${appName} - Contacts`,
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: 'directory',
        meta: {
          requiresAuth: true,
        },
        components: {
          default: () => import(/* webpackChunkName: "people" */ '@/views/people/ContactList.vue'),
        },
        children: [
          {
            path: '',
            name: RouteNames.USER_DIRECTORY_ROUTE_NAME,
            meta: {
              title: `${appName} - Company Directory`,
              requiresAuth: true,
            },
            components: {
              default: () => import(/* webpackChunkName: "people" */ '@/views/people/ContactList.vue'),
            },
          },
          {
            path: 'external',
            name: RouteNames.EXTERNAL_DIRECTORY_ROUTE_NAME,
            meta: {
              title: `${appName} - External Directory`,
              requiresAuth: true,
            },
            components: {
              default: () => import(/* webpackChunkName: "people" */ '@/views/people/ContactList.vue'),
            },
          },
        ],
      },
    ]
  },
  {
    path: '/n',
    name: RouteNames.NOTIFICATIONS_ROUTE_NAME,
    component: () => import(/* webpackChunkName: "notifications" */ '@/views/NotificationsView.vue'),

    meta: {
      title: `${appName} - Notifications`,
      requiresAuth: true,
    },
  },
  // Existing routes:
  {
    path: '/icons',
    name: RouteNames.ICONS_ROUTE_NAME,
    component: () => import(/* webpackChunkName: "dev" */ '@/views/DevIcons.vue'),
    meta: {
      title: `${appName} - Icons`,
      requiresAuth: true,
    },
  },
  {
    path: '/components',
    name: RouteNames.COMPONENTS_ROUTE_NAME,
    component: () => import(/* webpackChunkName: "dev" */ '@/views/DevComponents.vue'),
    meta: {
      title: `${appName} - Components`,
      requiresAuth: true,
    },
  },
  {
    path: '/browserInfo',
    name: RouteNames.BROWSER_INFO_ROUTE_NAME,
    component: () => import(/* webpackChunkName: "dev" */ '@/views/BrowserInfo.vue'),
    meta: {
      title: `${appName} - Browser info`,
      requiresAuth: true,
    },
  },
  {
    path: '/gradients',
    name: RouteNames.GRADIENTS_ROUTE_NAME,
    component: () => import(/* webpackChunkName: "dev" */ '@/views/DevGradients.vue'),
    meta: {
      title: `${appName} - Gradients`,
      requiresAuth: true,
    },
  },
  {
    path: '/profile',
    name: 'ProfileSettings',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/ProfileSettings.vue'),
    meta: {
      title: `${appName} - Profile`,
      requiresAuth: true,
    },
  },
  {
    path: '/tenant/:tenantId/:page',
    name: RouteNames.TENANT_SETTINGS_ROUTE_NAME,
    component: () => import(/* webpackChunkName: "tenantadmin" */ '@/views/TenantSettingsPage.vue'),
    meta: {
      title: `${appName} - Administration`,
      requiresAuth: true,
      requiresTenantAdmin: true,
    },
    children: [
      {
        path: 't/:trackId',
        name: RouteNames.TENANT_SETTINGS_TRACK_ROUTE_NAME,
        meta: {
          requiresAuth: true,
          requiresTenantAdmin: true,
        }
      },
    ],
  },
  {
    path: '/admin/:page',
    name: RouteNames.SYSTEM_SETTINGS_ROUTE_NAME,
    component: () => import(/* webpackChunkName: "systemadmin" */ '@/views/SystemSettings.vue'),
    meta: {
      title: `${appName} - System Admin`,
      requiresAuth: true,
      requiresSystemAdmin: true,
    },
  },
  {
    path: '/admin/accounts/:accountId',
    name: RouteNames.SYSTEM_SETTINGS_ACCOUNT_ROUTE_NAME,
    component: () => import(/* webpackChunkName: "systemadmin" */ '@/views/SystemSettings.vue'),
    meta: {
      title: `${appName} - System Admin`,
      requiresAuth: true,
      requiresSystemAdmin: true,
    },
  },
  {
    path: '/admin/accounts/:accountId/:cafexIdentityId',
    name: RouteNames.SYSTEM_SETTINGS_CAFEX_IDENTITY_ROUTE_NAME,
    component: () => import(/* webpackChunkName: "systemadmin" */ '@/views/SystemSettings.vue'),
    meta: {
      title: `${appName} - System Admin`,
      requiresAuth: true,
      requiresSystemAdmin: true,
    },
  },

  // Top level Chat
  // TODO Add subroutes that display chat, entries, or meeting in the primary (i.e. default) child router-view. This
  //      is required for mobile view, which will not show all of the child router-view content (i.e. default,
  //      secondary, and tertiary routes) simultaneously
  {
    path: '/c',
    name: 'Chat',
    component: () => import(/* webpackChunkName: "chat" */ '@/views/MainChat.vue'),

    meta: {
      title: `${appName} - Chat`,
      requiresAuth: true,
    },
    children: [
      // select and navigate to most recent chat
      {
        path: '',
        name: 'MostRecentChat',
        component: () =>
          import(/* webpackChunkName: "chat" */ '@/views/chat/MostRecentChat.vue'),
        meta: {
          requiresAuth: true,
        },
      },

      // view private chat
      {
        path: ':trackId',
        components: {
          default: () => import(/* webpackChunkName: "chat" */ '@/views/chat/ChatBase.vue'),
          tertiary: () => import(/* webpackChunkName: "chat" */ '@/views/chat/private/ChatSideBar.vue'),
        },
        children: [
          {
            path: '',
            name: RouteNames.PRIVATE_CHAT_ROUTE_NAME,
            meta: {
              requiresAuth: true,
            },
            components: {
              default: () => import(/* webpackChunkName: "chat" */ '@/views/chat/private/PrivateChatChat.vue'),
            }
          },
          {
            path: 'meet',
            name: RouteNames.CHAT_MEETING_ROUTE_NAME,
            meta: {
              // TODO: Do we need auth for direct calling, as we don't have waiting room options?
              requiresAuth: true,
            },
            components: {
              default: () => import(/* webpackChunkName: "chat" */ '@/components/meetings/TrackMeeting.vue'),
            },
          },
          {
            path: 'ct/:parentChatId',
            name: RouteNames.PRIVATE_CHAT_SINGLE_THREAD_ROUTE_NAME,
            meta: {
              requiresAuth: true,
            },
            components: {
              default: () => import(/* webpackChunkName: "chat" */ '@/views/chat/SingleChatThreadView.vue'),
            },
            props: {
              default: true,
            }
          },
          {
            path: 'e',
            name: RouteNames.CHAT_ENTRIES_ROUTE_NAME,
            meta: {
              requiresAuth: true,
            },
            components: {
              default: () => import(/* webpackChunkName: "chat" */ '@/views/chat/ChatEntriesView.vue'),
            },
            props: {
              default: true,
            }
          },
          {
            path: 'e/:entryId',
            components: {
              // As we need to handle entry type links, may change later when actual entry is implemented
              default: () => import(/* webpackChunkName: "track" */ '@/components/track/TrackFileEntry.vue'),
            },
            props: {
              default: true,
            },
            children: [
              {
                path: '',
                name: RouteNames.CHAT_ENTRY_ROUTE_NAME,
                meta: {
                  requiresAuth: true,
                  extraAuth: meetingGuestHandler,
                },
                props: {
                  default: true,
                },
              },
            ],
          },
        ],
      },
    ]
  },
  {
    path: '/contextcards',
    alias: '/contextcards/*',
    name: RouteNames.CONTEXT_CARDS_ROUTE_NAME,
    component: () => import(/* webpackChunkName: "contextcards" */ '@/views/ContextCardEditor.vue'),
    meta: {
      title: `${appName} - Context Cards`,
      requiresAuth: true,
    },
    props: true,
    children: [
      {
        path: ':cardId',
        name: RouteNames.EDIT_CONTEXT_CARD_ROUTE_NAME,
        meta: {
          requiresAuth: true,
        },
        props: true,
      },
    ],
  },
  {
    path: '/integration/*',
    redirect: '/workflows/*',
  },
  {
    path: '/k/:articlePath*',
    name: RouteNames.KNOWLEDGE_MANAGEMENT_ROUTE_NAME,
    component: () => import(/* webpackChunkName: "knowledgeMgmt" */ '@/views/KnowledgeMgmt.vue'),
    meta: {
      title: `${appName} - Knowledge management`,
      requiresAuth: true,
    },
  },
  {
    path: '/apps/:tenantAppId/cvs/:customViewId?',
    name: RouteNames.APP_STUDIO_ROUTE_NAME,
    component: () => import(/* webpackChunkName: "appstudio" */ '@/views/AppStudio.vue'),
    meta: {
      title: `${appName} - App studio`,
      requiresAuth: true,
    },
    children: [
      {
        path: 'cv',
        name: RouteNames.APP_STUDIO_CUSTOM_VIEW_ROUTE_NAME,
        component: () => import(/* webpackChunkName: "miniapp-admin-customview" */
          '@/views/uidesigner/EditCustomView.vue'),
        meta: {
          requiresAuth: true,
        },
        props: true,
      },
      {
        path: 'd',
        name: RouteNames.APP_STUDIO_DATA_SOURCE_ROUTE_NAME,
        component: () => import(/* webpackChunkName: "miniapp-admin" */ '@/views/apps/EditApp.vue'),
        meta: {
          requiresAuth: true,
        },
        props: true,
      },
      {
        path: 'w',
        name: RouteNames.APP_STUDIO_WORKFLOWS_ROUTE_NAME,
        component: () => import(/* webpackChunkName: "miniapp-admin" */ '@/views/apps/EditApp.vue'),
        meta: {
          requiresAuth: true,
        },
        props: true,
        children: [
          {
            path: 'flows/:recipeId',
            name: RouteNames.APP_STUDIO_EDIT_WORKFLOW_ROUTE_NAME,
            component: () => {
              if (userStore.isUserFeatureEnabled(Features.ACTIVEPIECES)) {
                return import(
                  '@/components/tasks/ActivePiecesFlowView.vue');
              }
            },
            meta: {
              requiresAuth: true,
            },
            props: true,
          },
          {
            path: 'runs',
            name: RouteNames.APP_STUDIO_FLOW_RUNS_ROUTE_NAME,
            component: () => import(/* webpackChunkName: "miniapp-admin" */
              '@/components/tasks/ActivePiecesRunsView.vue'),
            meta: {
              requiresAuth: true,
            },
            props: true,
          },
          {
            path: 'connections',
            name: RouteNames.APP_STUDIO_FLOW_CONNECTIONS_ROUTE_NAME,
            component: () => import(/* webpackChunkName: "miniapp-admin" */
              '@/components/tasks/ActivePiecesConnectionsView.vue'),
            meta: {
              requiresAuth: true,
            },
            props: true,
          },
        ],
      },
      {
        path: 'r',
        name: RouteNames.APP_STUDIO_RULESETS_ROUTE_NAME,
        component: () => import(/* webpackChunkName: "miniapp-admin" */ '@/views/apps/EditApp.vue'),
        meta: {
          requiresAuth: true,
        },
        props: true,
        children: [
          {
            path: 'new',
            name: RouteNames.APP_STUDIO_CREATE_APP_RULESETS_ROUTE_NAME,
            component: () => {
              return import(/* webpackChunkName: "miniapp-admin-ruleset" */
                '@/views/rulesengine/RulesetView.vue');
            },
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'edit/:rulesetId',
            name: RouteNames.APP_STUDIO_EDIT_APP_RULESETS_ROUTE_NAME,
            component: () => {
              return import(/* webpackChunkName: "miniapp-admin-ruleset" */
                '@/views/rulesengine/RulesetView.vue');
            },
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'test/:rulesetId',
            name: RouteNames.APP_STUDIO_TEST_APP_RULESETS_ROUTE_NAME,
            component: () => {
              return import(/* webpackChunkName: "miniapp-admin-ruleset" */
                '@/views/rulesengine/DebugRulesetView.vue');
            },
          },
        ],
      },
      {
        path: 's',
        name: RouteNames.APP_STUDIO_SETTINGS_ROUTE_NAME,
        component: () => import(/* webpackChunkName: "miniapp-admin" */ '@/views/apps/EditApp.vue'),
        meta: {
          requiresAuth: true,
        },
        props: true,
      },
      {
        path: 'an',
        name: RouteNames.APP_STUDIO_ANALYTICS_ROUTE_NAME,
        component: () => import(/* webpackChunkName: "miniapp-admin" */ '@/views/apps/AppAnalytics.vue'),
        meta: {
          requiresAuth: true,
        },
        props: true,
      },
      {
        path: 'dt',
        name: RouteNames.APP_STUDIO_EDIT_APP_TABLES_ROUTE_NAME,
        component: () => import(/* webpackChunkName: "miniapp-admin" */ '@/views/apps/EditApp.vue'),
        meta: {
          requiresAuth: true,
        },
        props: true,
      },
      {
        path: 'u',
        name: RouteNames.APP_STUDIO_UI_FLOWS_ROUTE_NAME,
        component: () => import(/* webpackChunkName: "miniapp-admin" */ '@/views/apps/EditApp.vue'),
        meta: {
          requiresAuth: true,
        },
        props: true,
        children: [
          {
            path: 'new',
            name: RouteNames.APP_STUDIO_CREATE_UI_FLOW_ROUTE_NAME,
            component: () => {
              return import(/* webpackChunkName: "miniapp-admin-uiflow" */
                '@/views/apps/uiFlow/ConfigureUiFlow.vue');
            },
            meta: {
              requiresAuth: true,
            },
            props: true,
          },
          {
            path: 'edit/:uiFlowId',
            name: RouteNames.APP_STUDIO_EDIT_UI_FLOW_ROUTE_NAME,
            component: () => {
              return import(/* webpackChunkName: "miniapp-admin-uiflow" */
                '@/views/apps/uiFlow/ConfigureUiFlow.vue');
            },
            meta: {
              requiresAuth: true,
            },
            props: true,
          },
        ],
      },
      {
        path: 'wf',
        name: RouteNames.APP_STUDIO_WORKSPACE_LABELS_ROUTE_NAME,
        component: () => import(/* webpackChunkName: "miniapp-admin" */ '@/views/apps/EditApp.vue'),
        meta: {
          requiresAuth: true,
        },
        props: true,
      },
      {
        path: 'cc',
        name: RouteNames.APP_STUDIO_CONTEXT_CARDS_ROUTE_NAME,
        component: () => import(/* webpackChunkName: "miniapp-admin" */ '@/views/apps/EditApp.vue'),
        meta: {
          requiresAuth: true,
        },
        props: true,
        children: [
          {
            path: 'new',
            name: RouteNames.APP_STUDIO_CREATE_CONTEXT_CARD_ROUTE_NAME,
            component: () => import(/* webpackChunkName: "contextcards" */ '@/views/ContextCardEditor.vue'),
            meta: {
              requiresAuth: true,
            },
            props: true,
          },
          {
            path: 'edit/:contextCardId',
            name: RouteNames.APP_STUDIO_EDIT_CONTEXT_CARD_ROUTE_NAME,
            component: () => import(/* webpackChunkName: "contextcards" */ '@/views/ContextCardEditor.vue'),
            meta: {
              requiresAuth: true,
            },
            props: true,
          }
        ],
      },
      {
        path: 'ai',
        name: RouteNames.APP_STUDIO_AI_ROUTE_NAME,
        component: () => import(/* webpackChunkName: "appConfigAi" */ '@/views/configAI/AppConfigAI.vue'),
        meta: {
          requiresAuth: true,
        },
        props: true,
      },
    ]
  },
  {
    path: '/rules',
    name: RouteNames.RULES_ENGINE_ROUTE_NAME,
    component: () => import(/* webpackChunkName: "rulesengine" */ '@/views/RulesEngine.vue'),
    meta: {
      title: `${appName} - Rules Engine`,
      requiresAuth: true,
    },
    children: [
      {
        path: 'create',
        name: RouteNames.RULES_ENGINE_CREATE_RULESET_ROUTE_NAME,
        components: {
          default:
            () => import(/* webpackChunkName: "rulesengine" */ '@/views/rulesengine/RulesetView.vue'),
        },
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':rulesetId',
        name: RouteNames.RULES_ENGINE_EDIT_RULESET_ROUTE_NAME,
        component: () => import(/* webpackChunkName: "rulesengine" */ '@/views/rulesengine/RulesetView.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':rulesetId/test',
        name: RouteNames.RULES_ENGINE_DEBUG_RULESET_ROUTE_NAME,
        component: () => import(/* webpackChunkName: "rulesengine" */ '@/views/rulesengine/DebugRulesetView.vue'),
        meta: {
          requiresAuth: true,
        },
      }
    ]
  },
  {
    path: '/templates',
    name: RouteNames.TEMPLATES_ROUTE_NAME,
    component: () => import(/* webpackChunkName: "workspace-templates" */ '@/views/MainTracks.vue'),
    meta: {
      title: `${appName} - Templates`,
      requiresAuth: true,
    },
  },
  {
    path: '/publishtemplates',
    name: RouteNames.PUBLISH_TEMPLATES_ROUTE_NAME,
    component: () => import(/* webpackChunkName: "publishtemplates" */ '@/views/publishing/PublishTemplates.vue'),
    meta: {
      title: `${appName} - Publisher`,
      requiresAuth: true,
    },
    children: [
      {
        path: ':templateId',
        name: RouteNames.PUBLISH_TEMPLATES_CONFIG_ROUTE_NAME,
        components: {
          default:
            () => import(/* webpackChunkName: "publishtemplates" */ '@/views/publishing/EditPublishTemplateConfig.vue'),
        },
        props: {
          default: true,
        },
        meta: {
          requiresAuth: true,
        },
      },
    ]
  },
  {
    path: '/changes',
    name: RouteNames.RECENT_CHANGES_ROUTE_NAME,
    component: () => import(/* webpackChunkName: "recentchanges" */ '@/views/RecentChanges.vue'),
    meta: {
      title: `${appName} - Recent changes`,
      requiresAuth: true,
    },
  },
  {
    path: '/unsupported',
    name: RouteNames.UNSUPPORTED_BROWSER_ROUTE_NAME,
    meta: {
      title: `${appName} - Unsupported browser`,
      requiresAuth: false,
    },
    components: {
      default: () => import(/* webpackChunkName: "unsupported" */ '@/views/UnsupportedBrowser.vue'),
    }
  },
  {
    path: '/stats',
    name: RouteNames.STATS_ROUTE_NAME,
    component: () => import(/* webpackChunkName: "stats" */ '@/views/StatsDashboard.vue'),
    meta: {
      title: `${appName} - Stats Dashboard`,
      requiresAuth: false,
    },
  },
  {
    path: '/reporting',
    component: () => import(/* webpackChunkName: "reporting" */ '@/views/reporting/ReportingBase.vue'),
    meta: {
      title: `${appName} - Reporting`,
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: RouteNames.REPORTING_ROUTE_NAME,
        components: {
          default: () => import(/* webpackChunkName: "reporting" */ '@/views/reporting/MainReporting.vue'),
        },
        meta: {
          title: `${appName} - Reporting`,
          requiresAuth: true,
        },
      },
      {
        path: ':tenantId',
        name: RouteNames.REPORTING_TENANT_ROUTE_NAME,
        components: {
          default: () => import(/* webpackChunkName: "reporting" */ '@/views/reporting/TenantReporting.vue'),
        },
        meta: {
          title: `${appName} - Reporting`,
          requiresAuth: true,
        },
      },
      {
        path: ':tenantId/meetings',
        name: RouteNames.REPORTING_MEETINGS_ROUTE_NAME,
        components: {
          default: () => import(/* webpackChunkName: "reporting" */ '@/views/reporting/MeetingsReporting.vue'),
        },
        meta: {
          title: `${appName} - Meetings Reporting`,
          requiresAuth: true,
        },
      },
      {
        path: ':tenantId/meetings/:conferenceSessionId',
        name: RouteNames.REPORTING_MEETINGS_DETAIL_ROUTE_NAME,
        components: {
          default: () => import(/* webpackChunkName: "reporting" */ '@/views/reporting/MeetingInstanceDetails.vue'),
        },
        meta: {
          title: `${appName} - Meetings Details`,
          requiresAuth: true,
        },
      },
    ]
  },
  { // It would be more consistent for this to be a wrapper with a slot for the subordinate views
    // but it's more work/code currently without much/any benefit
    name: RouteNames.APP_ENVIRONMENT_MOST_RECENT_TRACK_VIEW_NAME,
    path: '/ae/:tenantAppId/e/:environmentId/t',
    component: () => import(/* webpackChunkName: "tracks" */ '@/components/release/EnvironmentAppNoTrack.vue'),
    meta: {
      title: `${appName} - Workspaces`,
      requiresAuth: true,
    },
  },
  {
    path: '/ae/:tenantAppId/e/:environmentId/t/:trackId',
    name: RouteNames.APP_ENVIRONMENT_TRACK_VIEW_NAME,
    component: () => import(/* webpackChunkName: "tracks" */ '@/components/release/EnvironmentAppView.vue'),
    meta: {
      title: `${appName} - Workspaces`,
      requiresAuth: true,
    },
    children: [
      {
        path: 'v/:viewId',
        name: 'EnvironmentAppView',
        meta: {
          title: `${appName} - Workspaces`,
          requiresAuth: true,
        },
      },
    ]
  },
  {
    path: '/aef/:tenantAppId/e/:environmentId/t/:trackId/v/:viewId',
    name: RouteNames.APP_ENVIRONMENT_TRACK_VIEW_FULL_SCREEN_NAME,
    component: () => import(/* webpackChunkName: "tracks" */ '@/components/release/EnvironmentAppFullScreen.vue'),
    meta: {
      title: `${appName} - Workspaces`,
      requiresAuth: true,
    },
  }
];

export class Routes {
  public static modalCleanupFunctions: Array<(event?: Event) => Event | undefined> = [];

  public static pushModal(cleanupFunction: (event?: Event) => Event | undefined): void {
    this.modalCleanupFunctions.push(cleanupFunction);
  }

  public static popModal(cleanupFunction: (event?: Event) => Event | undefined): void {
    const index = this.modalCleanupFunctions.indexOf(cleanupFunction);
    if (index > -1) {
      this.modalCleanupFunctions.splice(index, 1);
    }
  }

  public static isTopModal(cleanupFunction: (event?: Event) => Event | undefined): boolean {
    return this.modalCleanupFunctions.indexOf(cleanupFunction) === this.modalCleanupFunctions.length - 1;
  }

  public static clearModals(): void {
    this.modalCleanupFunctions = [];
  }
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// Add a capturing event listener on the window as a way to determine if the navigation was due to:
//   * a user click (where we close all modals and continue to navigate), or
//   * a browser history manipulation (where we close one and block the route change)
let lastUserAction: number = 0;
const CLICK_GUARD_TIMEOUT_MS: number = 10;
window.addEventListener('click', () => {
  // This listener will also process touch and enter keypress events
  // (as they result in a click event).
  lastUserAction = Date.now();
}, true);
window.addEventListener('keydown', (event: KeyboardEvent) => {
  // Protect against the case where the user uses the space key to activate the link.
  // This can happen with some components, which are then coded to trigger a path change (typically buttons).
  if (event.key === ' ') {
    lastUserAction = Date.now();
  }
}, true);

router.beforeEach(async (to: Route, from: Route, next: NavigationGuardNext) => {
  routerLog.debug(`beforeEach(${to.name}) :: entry`);
  // TODO: Add generic navigation guard that prevents us changing page.
  // Possibly a Pinia store as a centralised location.

  // Check if modals are currently open...
  if (Routes.modalCleanupFunctions.length) {
    routerLog.debug(`running ${Routes.modalCleanupFunctions.length} cleanup functions`);
    const now = Date.now();
    if (now < lastUserAction + CLICK_GUARD_TIMEOUT_MS) {
      // The user requested the route change, so close all modal dialogs and allow it to continue
      for (const cleanupFunction of Routes.modalCleanupFunctions) {
        cleanupFunction();
      }
      Routes.clearModals();
      // Don't call return here! Need to continue using normal path so that security checks and the like are done...
    } else {
      // The user probably hit the back button and there is a modal dialog up, close it instead of changing the route
      const cleanUpFunction = Routes.modalCleanupFunctions.pop();
      if (cleanUpFunction) {
        cleanUpFunction();
      }
      next(false); // abort the route change
      return; // No point doing the rest of the checks...
    }
  }

  // Check for unsupported browser and direct to the relevant route
  if (to.name !== RouteNames.UNSUPPORTED_BROWSER_ROUTE_NAME && to.name !== RouteNames.RECONNECT_ROUTE_NAME &&
    !BrowserDetection.isSupportedBrowser()) {
    routerLog.debug(`Unsupported browser: ${BrowserDetection.browserInfo}`);
    next({
      name: RouteNames.UNSUPPORTED_BROWSER_ROUTE_NAME,
    });
    return;
  }

  // Don't allow access to the unsupported browser page in a supported browser
  if (to.name === RouteNames.UNSUPPORTED_BROWSER_ROUTE_NAME) {
    if (BrowserDetection.isSupportedBrowser()) {
      routerLog.debug(`Browser is supported. Blocking unsupported browser page: ${BrowserDetection.browserInfo}`);
      next({
        name: RouteNames.HOME_ROUTE_NAME,
      });
      return;
    }
    routerLog.debug(`Unsupported browser: ${BrowserDetection.browserInfo}`);
    next();
    return;
  }

  const rootRoute = { path: '/' };

  if (!to?.name) {
    routerLog.debug(`Unrecognised route: ${to?.name}`);
    // We don't recognise this route - head to the root page
    next(rootRoute);
    return;
  }

  // Check if we're authenticated
  routerLog.debug('Checking if user is authenticated');
  const isUserAuthenticated: boolean = await userStore.isUserAuthenticated();
  routerLog.debug(`User is authenticated: ${isUserAuthenticated}`);

  // Check if the route is guest-only
  if (to.meta?.guestOnly && isUserAuthenticated) {
    routerLog.debug('Blocking access to guest-only route');
    // Send the authenticated user to the root page instead
    next(rootRoute);
    return;
  }

  let accessAllowed = false;
  // Check if the route requires authentication
  if (to.meta?.requiresAuth) {
    if (isUserAuthenticated && !to.meta.extraAuth) {
      // If there's no extra auth, and we're authenticated, we're good
      accessAllowed = true;
    } else if (to.meta.extraAuth) {
      routerLog.debug('Performing extra auth step');
      // Otherwise do the extra auth, even if we have a user token - we could require a guest token if we're
      // joining a meeting on another track (which we aren't a member of)
      try {
        const extraAuthState: ExtraAuthState = await to.meta.extraAuth(to, isUserAuthenticated, next);
        if (extraAuthState === ExtraAuthState.ACCESS_ALLOWED) {
          accessAllowed = true;
        } else if (extraAuthState === ExtraAuthState.REDIRECT) {
          // we don't want to carry on to next() if we've already requested a redirect
          return;
        }
      } catch (err) {
        log.error(`Failed to execute extra route auth handling: ${err}`);
      }
    }
    const isForceReauth = !!to.query.forceReauth;
    if ((!accessAllowed) || isForceReauth) {
      routerLog.debug('Sending user to the login page');
      goToLoginPage(to);
      return;
    }
  }

  if (to.meta?.requiresTenantAdmin) {
    routerLog.debug('Page requires tenant admin access');
    if (!userStore.isUserTenantAdmin) {
      next(rootRoute);
      return;
    }
  }

  if (to.meta?.requiresSystemAdmin) {
    routerLog.debug('Page requires system admin access');
    if (!userStore.isUserSystemAdmin) {
      next(rootRoute);
      return;
    }
  }

  // If the service worker has updated, then reload the page to pick it up as the user is already navigating
  if (reloadIfSwUpdated(from, to)) {
    // The page is about to reload (location.href change done by reloadIfSwUpdated)
    // So stops the router from interfering by calling return without calling "next()".
    return;
  }

  // When loading a track we need to check the track settings to see which view should be shown by default.
  let trackViewRoute: Location | undefined;
  // TRACK_DEFAULT_ROUTE_NAME is the route that's hit when we need to work out where to go.
  if (to.name === RouteNames.TRACK_DEFAULT_ROUTE_NAME && to.params.trackId) {
    // On initial page load the store won't have initialised yet
    const track: Track = tracksStore.tracks[to.params.trackId];
    const firstTab: { tab: TabInfo, wait?: boolean } | undefined = getFirstDisplayableTab(track?.enabledNavigationTabs);
    if (firstTab && !firstTab.wait) {
      // We won't hit here on an initial page load and will hit the fall-through in TrackMainBase instead.
      // We also won't hit this if the first tab is a UIFlow tab and we don't have the data available to check if it
      // is embedded or links away from the workspace view.
      // On subsequent page changes this will hit and is more efficient than allowing TrackMainBase to load.
      trackViewRoute = getRouteForTabInfo(track.id, firstTab.tab);
    }
  }

  if (to.path.startsWith('/t/')) {
    // Translate the old-style route to its new-style equivalent
    const redirectRoute = await translateNonAppRoute(to.path);
    if (redirectRoute) {
      next(redirectRoute);
      return;
    }
  }

  if (trackViewRoute) {
    next(trackViewRoute);
  } else if (to.query.arid) {
    // Ensure we remove the ARID from the URL (could still be there if user already had a login context)
    //
    // This is not for security reasons but rather to ensure that URL cut and paste don't embed it.
    // If the URL is shared we want the user to use their normal login, not to be restricted / automatically
    // pushed to a specific one. This is useful when a workspace has been shared between multiple tenants...
    // And, if the workspace was restricted to a specific tenant, a user who has used a different login
    // subsystem would just end up with an "access denied"...
    delete to.query.arid;

    // Activate the modified route...
    next({
      path: to.path,
      query: to.query,
      replace: true,
    });
  } else {
    // Continue to the requested page
    next();
  }

  // Set a default title for the route, if one has been configured
  if (to.meta?.title) {
    document.title = to.meta.title;
  }
  routerLog.debug('beforeEach :: exit');
});

router.afterEach(async (to: Route) => {
  // Update the route store with the current route (which will then be accessed from other stores)
  routeStore.routeChanged(to);

  // Update the service worker with our current route
  if (navigator.serviceWorker.controller) {
    navigator.serviceWorker.controller.postMessage({
      type: 'routeUpdate',
      routePath: to.fullPath,
    });
  }
});

router.onError((error: Error) => {
  routerLog.debug(`onError(${JSON.stringify(error)})`);
  // Ideally we'd catch a broader array of errors here, but there doesn't seem to be a
  // consistent set of errors, just one for failing to load a CSS chunk
  // Also unfortunate is that the passed error doesn't actually match the signature of an error
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if ((error as any).code === 'CSS_CHUNK_LOAD_FAILED' && serviceWorkerUpdated) {
    // If we fail getting a chunk between a service worker update and a page reload
    // there isn't really any way to recover, other than reloading, if we're not actually
    // in a meeting then we're probably not going to get there, or do anything else
    const inMeeting: OngoingMeetingState = meetingStore.inMeeting;
    if (inMeeting !== OngoingMeetingState.IN_MEETING) {
      window.location.reload();
    }
  }
});

export default router;
