import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, CancelToken } from 'axios';

import ScheduledMeeting from '@/data/datatypes/meeting/ScheduledMeeting';
import { ApnsFullEndpointProperties } from '@/data/datatypes/pushmessaging/pushmessaging.types';
import { ScanStatusResponse } from '@/data/datatypes/ScanStatus';

import Integrations from './config/Integrations';
import { AISettings } from './datatypes/AISettings';
import { DashboardStatsResponse } from './datatypes/DashboardStats';
import { Track, TrackWebhook } from './datatypes/Track';
import { TrackHistoryAdditionalInfo } from './datatypes/TrackHistoryAdditionalInfo';
import { PushRegistrationMessage } from './pushmessaging/PushRegistrationMessage';
import { VideoSdkCredentials } from './video/VideoSdkCredentials';

// Set up API request config
function axiosContext(): AxiosRequestConfig {
  return {
    withCredentials: true,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  };
}

function getCalendarEvents(start: string, end: string): string {
  return `${Integrations.BE}/api/calendar/me/events?start=${start}&end=${end}`;
}

function searchCalendarEvents(searchTerm: string): string {
  return `${Integrations.BE}/api/calendar/me/events?searchTerm=${searchTerm}`;
}

function getCalendarScheduleUrl(email: string, timeFrom: number, timeTo: number): string {
  return `${Integrations.BE}/api/calendar/${email}/schedule/${timeFrom}/${timeTo}`;
}

function getActivepiecesTokenUrl(appId?: string): string {
  return `${Integrations.BE}/api/apcs/token${appId ? '?appId=' + appId : ''}`;
}

function getTrackHistoryUrl(trackId: string): string {
  return `${Integrations.BE}/api/trackhistory/${trackId}`;
}

function getInvokeExternalWebhookUrl(trackId: string): string {
  return `${Integrations.BE}/api/track/${trackId}/extwebhook`;
}

function getInvokeExternalWebhookAsGuestUrl(trackId: string, guestId: string): string {
  return `${Integrations.BE}/api/track/${trackId}/guest/${guestId}/extwebhook`;
}

function getInvokeWebhookUrl(trackId: string, id: string): string {
  return `${Integrations.BE}/api/track/${trackId}/webhook/${id}/invocation`;
}

function getInvokeWebhooksUrl(trackId: string): string {
  return `${Integrations.BE}/api/track/${trackId}/webhookinvocation`;
}

function getApnsInfoUrl(): string {
  return `${Integrations.BE}/api/apns/v2/fullpushid`;
}

function getPushRegistrationUrl(): string {
  return `${Integrations.BE}/api/notifications/push-registration`;
}

function getStatsUrl(): string {
  return `${Integrations.BE}/api/stats`;
}

function getUserStatsUrl(): string {
  return `${getStatsUrl()}/users?useOnlineCache=true`;
}

function getTrackStatsUrl(): string {
  return `${getStatsUrl()}/tracks`;
}

function getTenantStatsUrl(): string {
  return `${getStatsUrl()}/tenants`;
}

function getFileScanStatusUrl(trackId: string, entryId: string): string {
  return `${Integrations.BE}/api/track/${trackId}/entry/${entryId}/scan/status`;
}

function getAISettingsUrl(): string {
  return `${Integrations.BE}/api/ai/settings`;
}

function getVideoTokenUrl(trackId: number | string): string {
  return `${Integrations.BE}/api/meeting/${trackId}/token`;
}

function getVideoTokenAsGuestUrl(trackId: number | string, guestId: string): string {
  return `${getVideoTokenUrl(trackId)}/guest/${guestId}`;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function httpGet(url: string): Promise<AxiosResponse<any>> {
  return new Promise((resolve, reject) => {
    // TODO: disconnect handling
    axios.get(url, axiosContext()).then((obj) => {
      resolve(obj);
    }).catch((error: AxiosError) => {
      reject(error);
    });
  });
}

/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars */
async function httpPost(url: string, payload?: any,
  uploadProgressCallback?: (event: ProgressEvent) => any,
  extraHeaders?: any, cancelToken?: CancelToken, ignoreInitialisedState: boolean = false):
  Promise<AxiosResponse<any>> {
  const context: AxiosRequestConfig = axiosContext();
  if (uploadProgressCallback) {
    context.onUploadProgress = uploadProgressCallback;
  }
  if (extraHeaders) {
    context.headers = Object.assign(context.headers || {}, extraHeaders);
  }
  if (cancelToken) {
    context.cancelToken = cancelToken;
  }
  const response: AxiosResponse = await axios.post(url, payload, context);
  return response;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
async function httpDelete(url: string, payload?: any): Promise<AxiosResponse<any>> {
  const context: AxiosRequestConfig = axiosContext();
  if (payload) {
    Object.assign(context, { data: payload });
  }
  const response: AxiosResponse = await axios.delete(url, context);
  return response;
}

export default class ServerData {
  public static SILENT_REFRESH_URL: string = `${Integrations.BE}/silentrefresh`;

  public static getCalendarEvents(start: string, end: string): Promise<ScheduledMeeting[]> {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      httpGet(getCalendarEvents(start, end)).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  public static searchCalendarEvents(searchTerm: string): Promise<ScheduledMeeting[]> {
    return new Promise((resolve, reject) => {
      // TODO sanitise searchTerm
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      httpGet(searchCalendarEvents(searchTerm)).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  public static getCalendarSchedule(email: string, timeFrom: number, timeTo: number): Promise<any> {
    return new Promise((resolve, reject) => {
      httpGet(getCalendarScheduleUrl(email, timeFrom, timeTo)).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  public static async getActivepiecesToken(appId?: string): Promise<string> {
    const tokenResponse = await httpGet(getActivepiecesTokenUrl(appId));
    return tokenResponse.data;
  }

  public static async getTrackHistoryAdditionalInfo(trackId: string): Promise<TrackHistoryAdditionalInfo> {
    try {
      const response: AxiosResponse<TrackHistoryAdditionalInfo> = await httpGet(getTrackHistoryUrl(trackId));
      return response.data;
    } catch (error) {
      throw new Error(`Failed to get track history: ${error}`);
    }
  }

  public static async invokeExternalWorkflow(trackId: string, workflowId: string, text: string,
    platformApi: boolean, guestId?: string): Promise<string> {
    const url = guestId ? getInvokeExternalWebhookAsGuestUrl(trackId, guestId) : getInvokeExternalWebhookUrl(trackId);
    const response: AxiosResponse<string> =
      await httpPost(url, { workflowId, message: text, platformApi });

    return JSON.stringify(response.data);
  }

  public static async invokeWebhook(track: Track, webhook: TrackWebhook | undefined, text: string): Promise<void> {
    if (!webhook || !webhook.id) {
      throw new Error('Can\'t invoke webhook, it has no id');
    }

    const response: AxiosResponse<void> = await httpPost(getInvokeWebhookUrl(track.id, webhook.id), { message: text });
  }

  public static async invokeWebhooks(track: Track, text: string): Promise<void> {
    const response: AxiosResponse<void> = await httpPost(getInvokeWebhooksUrl(track.id), { message: text });
  }

  public static async getApnsInfo(): Promise<ApnsFullEndpointProperties> {
    try {
      const response: AxiosResponse<ApnsFullEndpointProperties> = await httpGet(getApnsInfoUrl());
      return response.data;
    } catch (error) {
      throw new Error(`Failed to get push ID: ${error}`);
    }
  }

  public static async registerForPushNotifications(pushSubscriptionMessage: PushRegistrationMessage): Promise<void> {
    try {
      const response: AxiosResponse<void> = await httpPost(getPushRegistrationUrl(), pushSubscriptionMessage);
      return response.data;
    } catch (error) {
      throw new Error(`Failed to register for push messages: ${error}`);
    }
  }

  public static async unregisterPushNotifications(pushSubscriptionMessage: PushRegistrationMessage): Promise<void> {
    try {
      const response: AxiosResponse<void> = await httpDelete(getPushRegistrationUrl(), pushSubscriptionMessage);
      return response.data;
    } catch (error) {
      throw new Error(`Failed to unregister for push messages: ${error}`);
    }
  }

  public static getUserStats(): Promise<DashboardStatsResponse> {
    return new Promise((resolve, reject) => {
      httpGet(getUserStatsUrl()).then((response) => {
        resolve(response.data as DashboardStatsResponse);
      }).catch((error) => {
        reject(new Error(`Failed to get user stats: ${error}`));
      });
    });
  }

  public static getTrackStats(): Promise<DashboardStatsResponse> {
    return new Promise((resolve, reject) => {
      httpGet(getTrackStatsUrl()).then((response) => {
        resolve(response.data as DashboardStatsResponse);
      }).catch((error) => {
        reject(new Error(`Failed to get track stats: ${error}`));
      });
    });
  }

  public static getTenantStats(): Promise<DashboardStatsResponse> {
    return new Promise((resolve, reject) => {
      httpGet(getTenantStatsUrl()).then((response) => {
        resolve(response.data as DashboardStatsResponse);
      }).catch((error) => {
        reject(new Error(`Failed to get tenant stats: ${error}`));
      });
    });
  }

  public static getFileScanStatus(trackId: string, entryId: string): Promise<ScanStatusResponse> {
    return new Promise((resolve, reject) => {
      httpGet(getFileScanStatusUrl(trackId, entryId)).then((response) => {
        resolve(response.data as ScanStatusResponse);
      }).catch((error) => {
        reject(new Error(`Failed to get file scan status: ${error}`));
      });
    });
  }

  public static getAISettings(): Promise<AISettings> {
    return new Promise((resolve, reject) => {
      httpGet(getAISettingsUrl()).then((response) => {
        resolve(response.data as AISettings);
      }).catch((error) => {
        reject(new Error(`Failed to get AI settings: ${error}`));
      });
    });
  }

  public static async getVideoSDKToken(trackId: number | string): Promise<VideoSdkCredentials> {
    try {
      const token: VideoSdkCredentials =
        (await httpGet(getVideoTokenUrl(trackId))).data;
      return token;
    } catch (error) {
      throw new Error(`Failed to get video token: ${error}`);
    }
  }

  public static async getVideoSDKTokenAsGuest(trackId: number | string, guestId: string):
  Promise<VideoSdkCredentials> {
    try {
      const token: VideoSdkCredentials =
        (await httpGet(getVideoTokenAsGuestUrl(trackId, guestId))).data;
      return token;
    } catch (error) {
      throw new Error(`Failed to get video token as guest: ${error}`);
    }
  }
}
